import axios from 'axios';
import qs from 'qs';
import app from '@/config/app.js';
import { Loading } from 'element-ui';

const HttpRequest = function () {
  class HttpRequest {
    constructor () {

    }

    get (url, params = {}) {
      let baseUrl = app.apiUrl;
      params.access_token = app.globalData.accessToken;
      let requestUrl = `${(url.indexOf('http') == 0) ? url : baseUrl.concat(url)}?${qs.stringify(params)}`;
      const loading = Loading.service({ fullscreen: true, spinner: 'el-icon-loading', text: '拼命加载中', background: 'rgba(0, 0, 0, 0.8)'});
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: requestUrl
        }).then((response) => {
          loading.close();
          let data = response.data;
          if (data.status == 'error') {
            reject(data);
          } else {
            resolve(data);
          }
        })
          .catch((error) => {
            loading.close();
            console.log(error);
            reject(error);
          });
      });
    }

    post (url, params = {}, contentType = 'application/x-www-form-urlencoded') {
      let baseUrl = app.apiUrl;
      let requestUrl = (url.indexOf('http') == 0) ? url : baseUrl.concat(url);
      params.access_token = app.globalData.accessToken;
      const loading = Loading.service({ fullscreen: true, spinner: 'el-icon-loading', text: '拼命加载中', background: 'rgba(0, 0, 0, 0.8)'});
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: requestUrl,
          data: contentType == 'json' ? params : qs.stringify(params),
          headers: {
            'Content-Type': contentType == 'json' ? 'application/json' : 'application/x-www-form-urlencoded'
          }
        }).then((response) => {
          loading.close();
          let data = response.data;
          if (data.status == 'error') {
            reject(data);
          } else {
            resolve(data);
          }

        })
          .catch((error) => {
            loading.close();
            console.log(error);
            reject(error);
          });
      });

    }
  }

  return HttpRequest;
}();

export default new HttpRequest();